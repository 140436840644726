// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTAGRAM_TOKEN',
  stripe_token: 'STRIPE_PUBLISHABLE_KEY',
  paypal_token: 'PAYPAL_CLIENT_ID',
  firebaseConfig : {
    apiKey: 'AIzaSyCgTZSHOaADAROFAasVKpkO3-W-AS8ZrMQ',
    authDomain: 'skyler-3329f.firebaseapp.com',
    databaseURL: 'https://skyler-3329f.firebaseio.com',
    projectId: 'skyler-3329f',
    storageBucket: 'skyler-3329f.appspot.com',
    messagingSenderId: '696989210682',
    appId: '1:696989210682:web:63503d9c46c75897fb7d03',
    measurementId: 'G-3KWT9HK244'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
